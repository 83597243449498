body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.Btn-Blue-BG.active {
  background-color: rgb(42, 153, 42) !important;
}

:visited {
  color: rgb(0, 161, 158);
}

.playFont {
  font-family: 'Pathway Gothic One', sans-serif;
}

.navTitle {
  color: white;
}

.HoverClass1:hover {color: wheat !important;}
.HoverClass2:hover {color: rgb(255, 0, 0) !important;}
.HoverClassEdit:hover {color: rgb(126, 212, 255) !important;}
.HoverClassDelete:hover {color: rgb(255, 155, 155) !important;}

.descriptionContainer {
  display: flex,
}

.fallbackground {
  background-image: url("/src/images/fallphoto.jpg");
  background-size: cover;
  background-position: 'center';
  background-repeat: 'no-repeat';
}

.welcomeStyle {
  color: white;
  font-size: 1.2em;
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 1;
}
.accountStyle {
  color: white;
  font-size: 1.2em;
  text-decoration: none;
  position: absolute;
  left: 5px;
  top: 0;
  z-index: 1;
}
.aboutUs {
  display: flex;
}
.commentSize {
  width: 50%;
}

.commentButtons {
  margin: 3px;
}

@media only screen and (max-width: 600px) {
  .welcomeStyle {
    display: none;
  }
  .accountStyle {
    display: none;
  }
  .aboutUs {
    display: block;
  }
  .commentSize {
    width: 100%;
  }
  .commentButtons {
    font-size: 12px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) {
  .myAccount {
    display: none;
  }
}